.maincontent {
  background-color: rgba(254, 242, 232, 0.95);
}

.content {
  position: relative;
  width: 80%;
}

.contentMet {
  position: relative;
  width: 75%;
}

.content p,
.contentMet p {
  font-size: 3vw;
  line-height: 3.5vw;
  text-align: left;
  font-family: 'modelica-light', sans-serif;
  margin-bottom: 30px;
}

.content strong,
.contentMet strong {
  font-family: 'modelica-bold', sans-serif;
}

.fullText {
  font-family: 'modelica-med', sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-align: left;
}

.fullText p {
  margin-bottom: 20px;
}

.fullText p strong {
  font-family: 'modelica-bold', sans-serif;
}

.testimonials .testimony:first-child {
  position: relative;
  left: -15%;
}

.testimonials .testimony:before {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
}

.testimonials .testimony:first-child:before {
  width: 170%;
  height: 170%;
  background-image: url(../assets/images/svgs/bg-testimony1.svg);
  top: -20%;
  left: -20%;
}

.testimonials .testimony:nth-child(2) {
  position: relative;
  left: 25%;
}

.testimonials .testimony:nth-child(2):before {
  width: 220%;
  height: 220%;
  background-image: url(../assets/images/svgs/bg-testimony2.svg);
  top: -80%;
  left: -10%;
}

.testimonials .testimony:nth-child(3) {
  position: relative;
  left: -20%;
  top: -10%;
}

.testimonials .testimony:nth-child(3):before {
  width: 170%;
  height: 170%;
  background-image: url(../assets/images/svgs/bg-testimony3.svg);
  top: -30%;
  left: 5%;
}

.testimonials .testimony:nth-child(4) {
  position: relative;
  left: 20%;
  top: -30%;
}

.testimonials .testimony:nth-child(4):before {
  width: 170%;
  height: 170%;
  background-image: url(../assets/images/svgs/bg-testimony4.svg);
  top: -40%;
  left: -20%;
}

.contentExerpt p {
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .content p {
    font-size: 2.5vw;
    line-height: 3.5vw;
  }
}

@media screen and (max-width: 768px) {
  .maincontent {
    background-color: rgba(254, 242, 232, 1);
  }

  .content,
  .contentMet {
    width: 100%;
    margin-top: 30px;
  }
  .content p,
  .contentMet p {
    font-size: 19px;
    line-height: 25px;
  }

  .testimonials .testimony:first-child {
    transform: translateX(0%);
  }

  .testimonials .testimony:first-child:before {
    top: -30%;
    left: 00%;
  }

  .testimonials .testimony:nth-child(1),
  .testimonials .testimony:nth-child(2),
  .testimonials .testimony:nth-child(3),
  .testimonials .testimony:nth-child(4) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .testimonials .testimony:nth-child(2):before {
    width: 200%;
    height: 200%;
    top: -50%;
    left: -30%;
  }

  .testimonials .testimony:nth-child(4):before {
    width: 150%;
    height: 150%;
    top: -0%;
    left: -20%;
  }

  .fullText {
    font-size: 18px;
    line-height: 28px;
  }

  .fullText p {
    margin-bottom: 20px;
  }
}
