.caseItem {
  position: relative;
  box-sizing: border-box;
}

.caseItem:nth-child(odd) {
  align-self: flex-start;
}

.caseItem:nth-child(even) {
  align-self: flex-end;
}

.caseItem:nth-child(odd) .popUpSingleCase {
  left: 0;
}

.caseItem:nth-child(even) .popUpSingleCase {
  right: 0;
}

.caseItem:nth-child(2) {
  margin-top: 10%;
}

.caseItem:nth-child(3) {
  margin-top: 10%;
}

.caseItem:last-child {
  margin-bottom: 50px;
}

.caseItem:nth-child(3) .popUpSingleCase {
  transform: translateY(-30%);
}

.contentPopUp p {
  font-size: 18px;
  font-family: 'modelica-med', sans-serif;
}

.contentPopUp strong {
  font-family: 'modelica-bold', sans-serif;
  font-style: italic;
}

.popUpSingleCase {
  background: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 768px) {
  .popUpSingleCase {
    background: rgba(255, 255, 255, 1);
  }

  .caseItem:nth-child(3) .popUpSingleCase {
    transform: translateY(0%);
  }

  .caseItem:nth-child(2) {
    margin-top: 100px;
  }

  .caseItem:nth-child(3) {
    margin-top: 100px;
  }

  .caseItem:last-child {
    margin-top: 100px;
  }

  .contentPopUp p {
    font-size: 17px;
  }
}

@media screen and (max-width: 1536px) {
  .caseItem:nth-child(odd) {
    padding-right: 15%;
  }

  .caseItem:nth-child(even) {
    padding-left: 15%;
  }

  .contentPopUp p {
    font-size: 16px;
    font-family: 'modelica-med', sans-serif;
  }
}
