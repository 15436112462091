.content {
  position: relative;
}

.content p {
  font-size: 3vw;
  line-height: 3.5vw;
  text-align: left;
  font-family: 'modelica-bold', sans-serif;
  margin-bottom: 30px;
}

.content li {
  margin-bottom: 10px;
}

.content strong {
  font-family: 'modelica-bold', sans-serif;
}

.content ul {
  list-style-type: disc;
  text-align: left;
  padding-left: 30px;
  font-family: 'modelica-light', sans-serif;
  font-size: 28px;
}

.metList li:first-child h3 {
  color: #b3b3b3;
}

.metList li:nth-child(4) img {
  transform: scale(1.2);
}

.metList li:nth-child(5) img {
  transform: translateX(10px);
}

.svgContainer svg {
  height: 100%;
  width: auto;
}

@media screen and (max-width: 1400px) {
  .content p {
    font-size: 2.5vw;
    line-height: 3vw;
    text-align: left;
    font-family: 'modelica-bold', sans-serif;
    margin-bottom: 30px;
  }

  .content ul {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    margin-top: 10px;
  }
  .content p {
    font-size: 23px;
    line-height: 27px;
  }

  .content ul {
    font-size: 20px;
    line-height: 25px;
  }
}
