/* Inserimos primeiro o que é necessário ao Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'modelica-bold';
  src: url('./assets/fonts/bold/BwModelicaBold.eot');
  src: url('./assets/fonts/bold/BwModelicaBold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/bold/BwModelicaBold.woff') format('woff'),
    url('./assets/fonts/bold/BwModelicaBold.ttf') format('truetype'),
    url('./assets/fonts/bold/BwModelicaBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'modelica-light';
  src: url('./assets/fonts/light/BwModelicaLight.eot');
  src: url('./assets/fonts/light/BwModelicaLight.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/light/BwModelicaLight.woff') format('woff'),
    url('./assets/fonts/light/BwModelicaLight.ttf') format('truetype'),
    url('./assets/fonts/light/BwModelicaLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'modelica-med';
  src: url('./assets/fonts/medium/BwModelicaMedium.eot');
  src: url('./assets/fonts/medium/BwModelicaMedium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/medium/BwModelicaMedium.woff') format('woff'),
    url('./assets/fonts/medium/BwModelicaMedium.ttf') format('truetype'),
    url('./assets/fonts/medium/BwModelicaMedium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.popup-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background: transparent; /* Cor de fundo da trilha da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #b1b1b1; /* Cor do manipulador da barra de rolagem */
  border-radius: 8px; /* Arredondamento do manipulador */
  border: 2px solid white; /* Borda do manipulador */
}
